// React
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from 'images/Logo_1.png'
// Third Party
import {
    Box, Drawer, Toolbar, List,
    ListItemIcon, ListItemText, ListItem,
    Typography, Divider, ListItemButton
} from '@mui/material';
import { Quiz, Business } from '@mui/icons-material';

// CSS
import './Sidebar.css';

// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';

// Components
import OrganizationSidebar from './OrganizationSidebar';

const drawerWidth = 250;

const Sidebar = () => {
    const { globalAuthenticated } = useContext(GlobalAuthenticatedContext);
    const [org, setOrg] = React.useState({});
    const {t} = useTranslation();

    React.useEffect(() => {
        if (globalAuthenticated && globalAuthenticated.contextLoaded) {
            setOrg(globalAuthenticated.genesys);
        }
    }, [globalAuthenticated])

    const getAppVersion = () => {
        return process.env.REACT_APP_BUILD_VERSION;
    }

    return (
        <Fragment>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
               <Toolbar variant='dense' />
                <Box sx={{ overflow: 'auto' }}>
                    {globalAuthenticated && globalAuthenticated.contextLoaded && (
                        <React.Fragment>
                             <Box
                                        component="img"
                                        sx={{ height: 64, width: 250}}
                                        alt="E3S"
                                        src={logo}
                            />
                            <List component={'nav'} className='ignite-sidebar' sx={{ mb: 2 }}>                               
                                {/*<ListItemButton component={Link} key={'Organization'} selected={location.pathname.includes('organization')}  >
                                    <ListItemIcon>
                                        <Business />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="h10">{t('AppName')}</Typography>} />  
                                </ListItemButton>*/}
                                <OrganizationSidebar />
                                {/*<ListItemButton component={Link} to={`/guide`} key={'Guide'} selected={location.pathname.includes('guide')}>
                                    <ListItemIcon>
                                        <Quiz />
                                    </ListItemIcon>
                                    <ListItemText  
                                    primary={<Typography variant="h10">{t('Guide')}</Typography>} />
                                </ListItemButton>*/}
                            </List>
                        </React.Fragment>
                    )}
                </Box>
                <Box sx={{ mt: 'auto', pb: 1, mx: 'auto' }}>
                    <Typography variant="body2" color={'primary'} textAlign="center">
                        {globalAuthenticated && globalAuthenticated.contextLoaded && (
                            <Fragment>
                                {org.name} <br />
                            </Fragment>
                        )}
                        Version: {getAppVersion()}
                        <br />
                        &copy; 2023 Genesys
                    </Typography>
                </Box>
            </Drawer>
        </Fragment>
    )
};

export default Sidebar;