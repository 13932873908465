import React, { lazy, startTransition, Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import config from '../config';
// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';
import GlobalAuthenticatedContextModel from 'common/contexts/GlobalAuthenticatedContextModel';

// Third Party
import { themeOptions } from 'theme';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar, Backdrop, CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { SnackbarProvider } from 'notistack';

// Util
import RouteWrapper from 'common/components/routing/RouteWrapper';

// Route Components
const ChoiceLoginPage = lazy(() => import('pages/login/ChoiceLogin'));
const UnauthorizedPage = lazy(() => import('pages/Unauthorized'));
const ErrorFoundPage = lazy(() => import('pages/ErrorFound'));
const PageNotFoundPage = lazy(() => import('pages/PageNotFound'));
const OrganizationLoginCallbackPage = lazy(() => import('pages/login/OrganizationLoginCallback'));
const AppFoundryIndexPage = lazy(() => import('pages/appfoundry/index'));
const GuidePage = lazy(() => import('pages/common/Guide'));
    

// Route Routers
import OrganizationRouter from './organization/OrganizationRouter';

// Don't lazy load initial application entry, header, sidebar.
import Entry from './Entry';
import Header from 'common/components/layout/Header';
import Sidebar from 'common/components/layout/Sidebar';
import { frFR } from '@mui/material/locale';
import * as locales from '@mui/material/locale';
import * as dataGridLocales from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
// Utilities
import { getStorageItem } from 'common/utilities/applicationStorage';
// Constants
import { OrganizationConstants } from 'common/utilities/appConstants';
const ApplicationRouter = ({}) => {

    const [globalAuthenticated, setGlobalAuthenticated] = useState(GlobalAuthenticatedContextModel);
    const [locale, setLocale]  = React.useState('enUS');
    const[language, setLanguage]   = React.useState('en');
    const theme = useTheme();
    const { t, i18n } = useTranslation();
        

    React.useEffect(() => {
        i18n.changeLanguage(language);
    }, [locale]);

    React.useEffect(() => {
        
        let languagePref = getStorageItem(OrganizationConstants.Language, undefined, undefined, sessionStorage);
        if (globalAuthenticated && globalAuthenticated.contextLoaded && !languagePref) {
            languagePref = globalAuthenticated.user.languagePreference;            
        }
        if (languagePref){
            let locale = languagePref.length > 2 ? `${languagePref.substring(0 , 2)}${languagePref.substring(3).toUpperCase()}` : 
                        `${languagePref.substring(0)}${languagePref.substring(0).toUpperCase()}`;
            let language = languagePref.substring(0, 2);
            setLocale(locale);
            setLanguage(language);
        }
    }, [globalAuthenticated])
    const genesysTheme = React.useMemo(
      () => createTheme(theme, themeOptions, dataGridLocales[locale], locales[locale], locales[locale]),
      [locale, theme],
    );
    const LazyLoadedRoutesFallback = () => {
        return (
            <Backdrop sx={{ color: '#ff4f1f', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    };

  {/*}  const genesysTheme =  createTheme(
       themeOptions,
       frFR, // x-data-grid translations
       pickersfrFR, // x-date-pickers translations
       corefrFR // core translations,
  );*/}

    return (
        <BrowserRouter>
            <CssBaseline />
            <ThemeProvider theme={genesysTheme}>
                <GlobalAuthenticatedContext.Provider value={{ globalAuthenticated, setGlobalAuthenticated }}>
                    <SnackbarProvider>
                        <Box sx={{ display: 'flex' }}>
                            <Header />
                           {(globalAuthenticated && globalAuthenticated.contextLoaded && globalAuthenticated.appConfiguration.integrationId && 
                           <Sidebar/>)}
                            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                                <Toolbar variant='dense' />
                                <Grid2 container spacing={2}>
                                    <Grid2 xs={12}>
                                        <Suspense fallback={<LazyLoadedRoutesFallback />}>                                                                                                                                                                                                                                                                                                                                                                          
                                            <Routes>
                                                    <Route path="/" element={<RouteWrapper />}>
                                                    <Route index element={<Entry />} />                                            
                                                    {globalAuthenticated && globalAuthenticated.contextLoaded && (
                                                        <React.Fragment>
                                                            <Route path="organization/*" element={<OrganizationRouter />} />
                                                            <Route path="guide" element={<GuidePage />} />
                                                        </React.Fragment>   
                                                    )}
                                                    <Route path='appfoundry' element={<AppFoundryIndexPage />} />
                                                    <Route path='login' element={<ChoiceLoginPage />} />
                                                    <Route path='/oauth2/source/callback' element={<OrganizationLoginCallbackPage />} />
                                                    <Route path="unauthorized" element={<UnauthorizedPage />} />
                                                    <Route path="error" element={<ErrorFoundPage />} />
                                                    <Route path="*" element={<PageNotFoundPage />} />
                                                </Route>
                                            </Routes>
                                        </Suspense>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Box>
                    </SnackbarProvider>
                </GlobalAuthenticatedContext.Provider>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default ApplicationRouter;