// React
import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// Third Party
import { AppBar, Toolbar, Typography, Link, Button, Box, Tooltip, Menu, MenuItem, Divider, ListItemIcon } from '@mui/material';
import { CloudQueue, CloudSync, ContactMail, Login, Logout, NotificationImportant, Settings } from '@mui/icons-material';
import moment from 'moment';
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';

// CSS
import './Header.css'

// Utilities
import { setStorageItem, getStorageItem, removeStorageItem } from 'common/utilities/applicationStorage';
import { AuthenticationConstants, OrganizationConstants } from 'common/utilities/appConstants';

// Services
import { deleteAuthenticate, getHomeDivision } from 'services/api/authService';
import { getSelf } from 'services/api/gc/userService';
import { getOrgMe } from 'services/api/gc/organizationService';
import { getActivityMe } from 'services/api/activityService';
import { getUsersGrants } from 'services/api/authService';
import { getConfiguration, getAppConfiguration } from 'services/api/configService';

// Contexts
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';
import GlobalAuthenticatedContextModel from 'common/contexts/GlobalAuthenticatedContextModel';

// Configuration
import config from '../../../config';

// Hooks
import useNotification from 'common/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import icon from 'images/icon.png'
import { useSearchParams } from 'react-router-dom';
const flickerStyle = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
`

const Header = () => {
    const navigate = useNavigate();

    const [setNotification] = useNotification();

    const [loggingOutUser, setLoggingOutUser] = React.useState(false);

    const { globalAuthenticated, setGlobalAuthenticated } = React.useContext(GlobalAuthenticatedContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const {t} = useTranslation();
    const fetchUser = async () => {
        try {
            if (globalAuthenticated && !globalAuthenticated.contextLoaded) {
                globalAuthenticated.sessionLoaded = true;
                let isHomeAdmin = false;
                const { data: config } = await getConfiguration();
                const { data: appConfig } = await getAppConfiguration();
                const { data: user } = await getSelf();
                // const { data: userActivity } = await getActivityMe();
                const { data: org } = await getOrgMe();
                const { data: orgGrants } = await getUsersGrants();
                const { data: homeDivision } = await getHomeDivision();
                globalAuthenticated.configuration = {
                    ...config
                }     
                globalAuthenticated.appConfiguration = {
                    ...appConfig
                }             
                globalAuthenticated.userLoaded = true;
                globalAuthenticated.user = {
                    ...user.data,
                    isAdmin: orgGrants.grants.find(g=>g.role.name  === "admin" && (g.division.name === "Home" || g.division.id ==="*")) ? true : false
                };
                // globalAuthenticated.activity = [...userActivity];
                globalAuthenticated.genesys = {
                    ...org.data,
                    homeDivision: homeDivision.id
                };
                globalAuthenticated.grants = {
                    ...orgGrants
                }
                globalAuthenticated.contextLoaded = true;
                console.log(globalAuthenticated);
                setGlobalAuthenticated(Object.assign({}, globalAuthenticated));
            }
        } catch ({ data, success, error }) {

        } finally {

        }
    };

 

    React.useEffect(() => {
        const usePopup = searchParams.has("usePopupAuth");
        const langTag = searchParams.has("langTag") ? searchParams.get('langTag') : "";
        if (globalAuthenticated && !globalAuthenticated.contextLoaded) {
            if (!loggingOutUser) {
                const previousAuthenticated = getStorageItem(AuthenticationConstants.Key, undefined, undefined, sessionStorage);
                if (previousAuthenticated) {
                    console.log('User authenticated.');
                    fetchUser();
                } else {
                    console.log('User unauthenticated, navigating to login.');
                    const usePopStorage = getStorageItem(OrganizationConstants.UsePopup, undefined, undefined, sessionStorage);
                    const langStorage = getStorageItem(OrganizationConstants.Language, undefined, undefined, sessionStorage);
                    let navigateUrl = '/login?type=code';
                    if (usePopup || usePopStorage){
                        navigateUrl = `${navigateUrl}&usePopupAuth=true`;
                    } 
                    if (langTag || langStorage){
                        if (langTag) {
                            setStorageItem(OrganizationConstants.Language, langTag, undefined, undefined, sessionStorage);
                        }
                        navigateUrl = `${navigateUrl}&langTag=${langTag ? langTag:langStorage}`;
                    }            
                    navigate(navigateUrl);
                }
            }
        } 
        return () => {}
    }, [globalAuthenticated, globalAuthenticated.contextLoaded]);


    const logout = async () => {
        setAnchorEl(null);
        setLoggingOutUser(true);
        try {
            console.log("Session Ending");
            const { data } = await deleteAuthenticate();
            console.log("Session Destroyed");

            setGlobalAuthenticated(GlobalAuthenticatedContextModel);
            removeStorageItem(AuthenticationConstants.Key, undefined, undefined, sessionStorage);

            const queryStringData = {
                client_id: config.purecloud.client,
                redirect_uri: `${window.location.protocol}//${window.location.host}`
            };
            const params = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&');          
            const envFromSession = getStorageItem(OrganizationConstants.UrlAuth, undefined, undefined, sessionStorage);
            const envFromSessionSanitized = typeof (envFromSession) === 'string' && envFromSession.trim().length > 0 ? envFromSession.trim() : '';
            const href = `${envFromSessionSanitized}/logout?${params}`;
            console.log("Logging Out via: %o", href);
            window.location = href;
        } catch ({ data, success, error }) {

        } finally {
            setLoggingOutUser(false);
        }
    };

    const handleSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMailTo = (e) => {
        if (window) {
            window.location.href = "mailto:PSAWSRequests@genesys.com?subject=[Agency App] Help Request&body=See E3S user guide for more details regarding what to include in the body of this email.";
            e.preventDefault();
        }
        setAnchorEl(null);
        return;
    }

    const navigateToLogin = () => {
        const usePopup = getStorageItem(OrganizationConstants.UsePopup, undefined, undefined, sessionStorage);
        const language = getStorageItem(OrganizationConstants.Language, undefined, undefined, sessionStorage);
        let navigateUrl = '/login?type=code';
        if (usePopup){
            navigateUrl = `${navigateUrl}&usePopupAuth==${usePopup}`;
        } 
        if (language){
            navigateUrl = `${navigateUrl}&langTag=${language}`;
        }            
        navigate(navigateUrl);
    };


    return (
        <React.Fragment>
            <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar variant="dense">
                <Box
                                        component="img"
                                        sx={{ height: 25,  width: 25}}
                                        alt="E3S"
                                        src={icon}
                            />
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        <Link component={RouterLink} to='/organization' underline="none" color="inherit">E3S &#8482;</Link>
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {globalAuthenticated && globalAuthenticated.contextLoaded ? (
                        <React.Fragment>
                            <Button color="inherit" onClick={handleSettings}>{globalAuthenticated.user.name}</Button>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                              {/*  <MenuItem onClick={handleMailTo}>
                                    <ListItemIcon>
                                        <ContactMail fontSize="small" />
                                    </ListItemIcon>
                                    Help
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                            </MenuItem>*/}
                                <Divider />
                                <MenuItem onClick={logout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    {t('Logout')}
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button sx={{ ml: 2 }} color="inherit" onClick={navigateToLogin}>Login&nbsp;<Login fontSize='small' /></Button>
                        </React.Fragment>
                    )}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )

};

export default Header;