// private config
const baseConfiguration = {
    app: {
        storageVersion: 'v1',
        solutionKey: 'genesys-agency-app',
        solutionName: 'E3S'
    },
    //clientCX: 'e59f91fe-14d1-4af6-9844-3daea1b52165',
    client: 'aa0bd8fa-edc8-484b-8175-55ddb30ad37c', // PS Innovation Org > Agency App
    clientSecret: "9gaExWTRw2PLx5Ut0zeHj9Q0lePPrBYdzgj__BqnHCI",
    dailyScheduleDefaultMorningStart: 8,
    dailyScheduleDefaultMorningEnd: 12,
    dailyScheduleDefaultAfternoonStart: 13,
    dailyScheduleDefaultAfternoonEnd: 17,
    agencyApiEndpoint: {
        dev: 'https://agency-app.genesysinnoapps.net',
        development: 'https://agency-app-test.genesysinnoapps.net',
        sandbox: 'http://agency-app-alb-dev-971199578.us-east-1.elb.amazonaws.com',
        test: 'https://agency-app-test.genesysinnoapps.net',
        production: 'https://e3s-services.genesysinnoapps.com'
    },
    apiEndpoint: {
        local: 'https://dev.api.ignite.genesyspsdevops-dev.com', 
        sandbox: 'https://sandbox.api.ignite.genesyspsdevops-dev.com',
        dev: 'https://dev.api.ignite.genesyspsdevops-dev.com',
        test: 'https://test.api.ignite.genesyspsdevops-dev.com',
        production: 'https://api.ignite.genesyspsdevops.com'
    }
}

const local = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
        //clientCX: baseConfiguration.clientCX
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.local,
    agencyApiEndpoint: baseConfiguration.agencyApiEndpoint.dev,
    dailyScheduleDefaultMorningStart: baseConfiguration.dailyScheduleDefaultMorningStart,
    dailyScheduleDefaultMorningEnd: baseConfiguration.dailyScheduleDefaultMorningEnd,
    dailyScheduleDefaultAfternoonStart: baseConfiguration.dailyScheduleDefaultAfternoonStart,
    dailyScheduleDefaultAfternoonEnd: baseConfiguration.dailyScheduleDefaultAfternoonEnd,
    appName: "Agency App"
}

const development = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
        //clientCX: baseConfiguration.clientCX,
        clientSecret: baseConfiguration.clientSecret
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.dev,
    agencyApiEndpoint: baseConfiguration.agencyApiEndpoint.dev,
    dailyScheduleDefaultMorningStart: baseConfiguration.dailyScheduleDefaultMorningStart,
    dailyScheduleDefaultMorningEnd: baseConfiguration.dailyScheduleDefaultMorningEnd,
    dailyScheduleDefaultAfternoonStart: baseConfiguration.dailyScheduleDefaultAfternoonStart,
    dailyScheduleDefaultAfternoonEnd: baseConfiguration.dailyScheduleDefaultAfternoonEnd,
    appName: "Agency App Dev"
}

const test = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
        //clientCX: baseConfiguration.clientCX,
        clientSecret: baseConfiguration.clientSecret
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.test,
    dailyScheduleDefaultMorningStart: baseConfiguration.dailyScheduleDefaultMorningStart,
    dailyScheduleDefaultMorningEnd: baseConfiguration.dailyScheduleDefaultMorningEnd,
    dailyScheduleDefaultAfternoonStart: baseConfiguration.dailyScheduleDefaultAfternoonStart,
    dailyScheduleDefaultAfternoonEnd: baseConfiguration.dailyScheduleDefaultAfternoonEnd,
    agencyApiEndpoint: baseConfiguration.agencyApiEndpoint.test,
    appName: "Agency App Test"
}

const sandbox = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
        //clientCX: baseConfiguration.clientCX,
        clientSecret: baseConfiguration.clientSecret
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.sandbox,
    dailyScheduleDefaultMorningStart: baseConfiguration.dailyScheduleDefaultMorningStart,
    dailyScheduleDefaultMorningEnd: baseConfiguration.dailyScheduleDefaultMorningEnd,
    dailyScheduleDefaultAfternoonStart: baseConfiguration.dailyScheduleDefaultAfternoonStart,
    dailyScheduleDefaultAfternoonEnd: baseConfiguration.dailyScheduleDefaultAfternoonEnd,
    appName: "Agency App Sandbox"
}

const production = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
        //clientCX: baseConfiguration.clientCX,
        clientSecret: baseConfiguration.clientSecret
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.production,
    agencyApiEndpoint: baseConfiguration.agencyApiEndpoint.production,
    dailyScheduleDefaultMorningStart: baseConfiguration.dailyScheduleDefaultMorningStart,
    dailyScheduleDefaultMorningEnd: baseConfiguration.dailyScheduleDefaultMorningEnd,
    dailyScheduleDefaultAfternoonStart: baseConfiguration.dailyScheduleDefaultAfternoonStart,
    dailyScheduleDefaultAfternoonEnd: baseConfiguration.dailyScheduleDefaultAfternoonEnd,
    appName: "Agency App"
}

const env = process.env.REACT_APP_CUSTOM_ENV && process.env.REACT_APP_CUSTOM_ENV.length > 0 ? process.env.REACT_APP_CUSTOM_ENV.trim() : '';
const build = process.env.REACT_APP_BUILD_VERSION && process.env.REACT_APP_BUILD_VERSION.length > 0 ? process.env.REACT_APP_BUILD_VERSION.trim() : '';
console.log('Found an issue, or need to report a bug. Please reach out to PSAWSRequests@genesys.com');
console.log('Application Environment: %o', env);
console.log('Application Version: %o', env === 'local' ? 'sandbox build' : build);
let config
switch (env) {
    case 'production': config = production; break;
    case 'test': config = test; break;
    case 'development': config = development; break;
    case 'sandbox': config = sandbox; break;
    case 'local': config = local; break;
    default: break;
}

export default config
export const headerTitle = "E3S";