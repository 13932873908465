import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

 
// Configuration
import config from '../../config';

// Utilities
import { getStorageItem, setStorageItem } from 'common/utilities/applicationStorage';
import { baseUri } from 'common/utilities/fetchWrap';
import { AuthenticationConstants, OrganizationConstants } from 'common/utilities/appConstants';

// Services
import { getSelf } from 'services/api/gc/userService';
import { getOrgMe } from 'services/api/gc/organizationService';
import { getActivityMe } from 'services/api/activityService';
import { postAuthenticate, getUsersGrants } from 'services/api/authService';
import { getConfiguration } from 'services/api/configService';

// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';

const OrganizationPage = React.lazy(() => import('pages/organization/Organization'));

const OrganizationPeoplePage = React.lazy(() => import('pages/organization/people/People'));
const OrganizationRoutingPage = React.lazy(() => import('pages/organization/routing/Routing'));
const OrganizationPeopleUsersPage = React.lazy(() => import('pages/organization/people/users/Users'));
const OrganizationQueuesPage = React.lazy(() => import('pages/organization/routing/queues/Queues'));

const OrganizationTelephonyPage = React.lazy(() => import('pages/organization/telephony/Telephony'));
const OrganizationDidsPage = React.lazy(() => import('pages/organization/telephony/dids/DIDs'));
const OrganizationBlackListPage = React.lazy(() => import('pages/organization/datatables/blacklist/BlackList'));
const OrganizationDataTablePage = React.lazy(() => import('pages/organization/datatables/DataTables'));

const OrganizationCallFlowsPage = React.lazy(() => import('pages/organization/callflows/CallFlows'));
const OrganizationCallFlowsOpenFlowPage = React.lazy(() => import('pages/organization/callflows/openflow/OpenFlow'));
const OrganizationCallFlowsClosedFlowPage = React.lazy(() => import('pages/organization/callflows/closedflow/ClosedFlow'));


const OrganizationSchedulesRoutingSchedulesPage = React.lazy(() => import('pages/organization/callflows/dailyschedules/DailySchedules'));
const OrganizationSchedulesRoutingExceptionSchedulesPage = React.lazy(() => import('pages/organization/callflows/exception-schedules/ExceptionSchedules'));

const PageNotFoundPage = React.lazy(() => import('pages/PageNotFound'));

const OrganizationRouter = ({ }) => {

       
    return (
        <Routes>
            <Route index element={<OrganizationPage />} />
            <Route path="people" element={<OrganizationPeoplePage />}>
                <Route path="users" element={<OrganizationPeopleUsersPage />} /> 
            </Route>
            <Route path="routing" element={<OrganizationRoutingPage />}>
                <Route path="queues" element={<OrganizationQueuesPage />} /> 
            </Route>            
            <Route path="telephony" element={<OrganizationTelephonyPage />}>
                <Route path="dids" element={<OrganizationDidsPage />} />
            </Route>
            <Route path="callflows" element={<OrganizationCallFlowsPage />}>
                <Route path="openflow" element={<OrganizationCallFlowsOpenFlowPage />} />
                <Route path="closedflow" element={<OrganizationCallFlowsClosedFlowPage />} />
                <Route path="dailyschedules" element={<OrganizationSchedulesRoutingSchedulesPage />} />
                <Route path="exception-schedules" element={<OrganizationSchedulesRoutingExceptionSchedulesPage />} />
            </Route>
            
            <Route path="datatables" element={<OrganizationDataTablePage />}>
                <Route path="blacklist" element={<OrganizationBlackListPage />} />
            </Route>
            <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
    );
};

export default OrganizationRouter;