import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { registerElements } from 'genesys-spark-components';

import {
    List,
    ListItemIcon, ListItemText, 
    ListItemButton,
    Collapse, Divider, Typography
} from '@mui/material';

import { TableRows, People, Phone, AccountTree, Quiz } from '@mui/icons-material';


const OrganizationSidebar = () => {
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    registerElements();
    
    const [orgToggle, setOrgToggle] = useState(false);

    useEffect(() => {
        if (isMounted && location && location.pathname !== '') {
            if (location.pathname === '/') {
                setOrgToggle(false);
            } else if (location.pathname.includes('organization/') || location.pathname.includes('organization')) {
                setOrgToggle(true);
            }
        }

        return () => {
            isMounted.current = false;
        }
    }, [location.pathname])

    return (
        <Fragment>
         
                <List component="div" disablePadding>                     
                    <ListItemButton component={Link} to={`/organization/routing`} key={'Org-Queues'} selected={location.pathname.includes('organization/routing')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            {/*<People />*/}
                            <gux-icon icon-name="agent-group" decorative></gux-icon>
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h10">{t('Queues')}</Typography>} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton component={Link} to={`/organization/people`} key={'Org-People'} selected={location.pathname.includes('organization/people')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            {/*<People />*/}
                            <gux-icon icon-name="user" decorative></gux-icon>
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h10">{t('Users')}</Typography>} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton component={Link} to={`/organization/telephony`} key={'Org-Telephony'} selected={location.pathname.includes('organization/telephony')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            {/*<Phone />*/}
                            <gux-icon icon-name="phone" decorative></gux-icon>
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h10">{t('DIDs')}</Typography>} />
                    </ListItemButton>                   
                    <Divider />
                    <ListItemButton component={Link} to={`/organization/callflows`} key={'Org-CallFlows'} selected={location.pathname.includes('organization/callflows')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            {/*<AccountTree />*/}
                            <gux-icon icon-name="fa/sitemap-regular" decorative></gux-icon>
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h10">{t('CallFlows')}</Typography>} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton component={Link} to={`/organization/datatables`} key={'Org-Datatables'} selected={location.pathname.includes('organization/datatables')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            {/*<TableRows />*/}
                            <gux-icon icon-name="ic-list-numbers" decorative></gux-icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="h10">{t('Blacklist')}</Typography>}
                        />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton component={Link} to={`/guide`} key={'Guide'} selected={location.pathname.includes('guide')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            {/*<Quiz />*/}
                            <gux-icon icon-name="help" decorative></gux-icon>
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="h10">{t('Guide')}</Typography>} />
                    </ListItemButton>                  
                </List>
            
        </Fragment>
    )
};

export default OrganizationSidebar;