// User Service - Contains the api endpoints to the backend to grab user information.

import { fetchWithRetries, baseUri, baseUriAgency, fetchWrap } from 'common/utilities/fetchWrap';


 /**
 * @description Get's the current authenticated user that is connected via the backend and has authenticated to genesys cloud.
 * @return {*} Current User
 */
export const getSelf = () => {
    const url = `${baseUriAgency()}/api/me`;
    // const url = `http://localhost:5000/api/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}

/**
 * @description Get's a full list of users from the organization.
 * @return {*} List User entities.
 */
export const getUsers = () => {
    // const url = `${baseUri()}/users`;
    const url = `${baseUriAgency()}/api/users`
    
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}

/**
 * @description Get's a full list of roles for the user.
 * @return {*} List User roles.
 */
export const getRoles = (id) => {
    const url = `${baseUriAgency()}/users/${id}/roles`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}

 
/**
 * @description Get's a full list of skills for the user.
 * @return {*} List User skills.
 */
export const getUserSkills = (id) => {
    const url = `${baseUriAgency()}/api/user/${id}/skill`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}
/**
 * @description Get's a full list of queues for the user.
 * @return {*} List User queues.
 */
export const getUserQueues = (id) => {
    const url = `${baseUriAgency()}/api/user/${id}/queues`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}
/**
 * @description Get's a full list of roles for the user.
 * @return {*} List User roles.
 */
export const getUserRoles = (id) => {
    const url = `${baseUriAgency()}/api/user/${id}/roles`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
}
/**
 * @description Get's a users license.
 * @return {*} response
 */
export const getUserLicense = (id) => {
    var body = [id];
    const url = `${baseUriAgency()}/api/license/users`;
    return fetchWithRetries(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
       
}
/**
 * @description updates users's skills
 * @return {*} response.
 */
export const putUserSkills = (id, skills) => { 

      var body = JSON.stringify(skills.map(({ id, proficiency }) => ({ id, proficiency })));
      const url = `${baseUriAgency()}/api/user/${id}/skill`;
      return fetchWithRetries(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      })
        
}

/**
 * @description create users
 * @return {*} response.
 */
export const postUser = (data) => { 
    var body = { 
        name: `${data.name}`,
        email: `${data.email}`,
        title: `${data.title}`,
        divisionId: `${data.divisionId}`
    }
    const url = `${baseUriAgency()}/api/user`;
      return fetchWithRetries(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
       
}

/**
 * @description update user
 * @return {*} response.
 */
export const patchUser = (data) => { 
  var body = { 
      name: `${data.name}`,
      email: `${data.email}`,
      title: `${data.title}`       
  }
  const url = `${baseUriAgency()}/api/user/${data.id}`;
    return fetchWithRetries(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
     
}
/**
 * @description get users's based on division
 * @return {*} response.
 */
export const getUsersForDivision = (division) => { 

    const url = `${baseUriAgency()}/api/users`    
    return fetchWithRetries(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }    
    })
     
}

/**
 * @description updates users's roles
 * @return {*} response.
 */
export const putUserRoles = (id, roles) => { 

    var body = roles;
    const url = `${baseUriAgency()}/api/user/${id}/roles`;
    return fetchWithRetries(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
     
}

/**
 * @description updates users's role with division
 * @return {*} response.
 */
export const postUserRoles = (id, roles) => { 

  var body = roles;
  const url = `${baseUriAgency()}/api/userRolesBulk/${id}`;
  return fetchWithRetries(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
   
}
/**
 * @description Delete a user with given id.
 * @return {*} response.
 */
export const deleteUser = (id) => { 

  const url = `${baseUriAgency()}/api/user/${id}`;
  return fetchWithRetries(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }     
  })
    
}

/**
 * @description deletes users's roles
 * @return {*} response.
 */
export const deleteUserRoles = (userId, roles) => { 

  var body = roles
  const url = `${baseUriAgency()}/api/roles/${userId}`;
  return fetchWithRetries(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
   
  
}

/**
 * @description Get's a full list of users from the organization.
 * @return {*} List User entities.
 */
export const  getWebRTCUsers = (ids,  divisionId) => {
  // only retrieve users that have phones for a faster query
  // only works if number of users is less <= 100
  if (ids.length > 100)
      return getUsersByDivision(divisionId)
  // const url = `${baseUri()}/users`;
  const url = `${baseUriAgency()}/api/users`
  let  urlWithIds = url;
  if (ids.length > 0){
    urlWithIds = url + "?id="+ids;
  }
  return fetchWithRetries(urlWithIds, {
      method: 'GET'
  }, '', false, true);
}

export const getUsersByDivision = (divId) => { 

  var body = {
    division: divId
  };
  const url = `${baseUriAgency()}/api/postUserSearchByDivision`;
  return fetchWithRetries(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
   
}

export default {
    getSelf,
    getUsers,
    getRoles,
    getUserSkills,
    putUserSkills,
    getUserQueues,
    getUsersForDivision,
    postUser,
    deleteUser,
    patchUser,
    putUserRoles,
    deleteUserRoles,
    getWebRTCUsers,
    postUserRoles,
    getUsersByDivision
}