import { isEmpty } from "lodash";
const GlobalAuthenticatedContextModel = {
    configuration: {},
    appConfiguration: {},
    activity: [],
    grants: {},
    user: {},
    genesys: {},
    sessionLoaded: false,
    userLoaded: false,
    contextLoaded: false,
    isAdmin: false,
    permittedPhones: null,
    permittedUsers: null
};

export default GlobalAuthenticatedContextModel;