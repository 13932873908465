import { fetchWithRetries, baseUri, baseUriAgency } from 'common/utilities/fetchWrap';

/**
 * @description Gets the current configuration items from the ignite node backend.
 * @return {Array} 
 */
export const getConfiguration = () => {
    // const url = `${baseUri()}/config`;
    const url = `${baseUriAgency()}/api/config`
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Gets the current configuration items from the ignite node backend.
 * @return {Array} 
 */
export const getAppConfiguration = () => {
    const url = `${baseUriAgency()}/api/orgConfig`
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

export default {
    getConfiguration,
    getAppConfiguration
}