import { fetchWithRetries, baseUri,baseUriAgency } from 'common/utilities/fetchWrap';

/**
 * @description Gets the current organization information.
 * @return {Object} 
 */
export const getOrgMe = () => {
    // const url = `${baseUri()}/organizations/me`;
    const url = `${baseUriAgency()}/api/orgme`;
    // const url = `http://localhost:5000/api/orgme`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', false, true);
};

/**
 * @description Gets the current organization information using the raw token implicit grant for destination auth.
 * @return {Object} 
 */
export const getDestinationOrgMe = (token) => {
    // pull destination org information from secure storage.
    const url = `${baseUri()}/organizations/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token, true, false);
};

/**
 * @description Post the current organization information using the raw token implicit grant for destination auth.
 * @return {Object} 
 */
export const postOrganization = (fields) => {
    // pull destination org information from secure storage.
    const url = `${baseUri()}/organizations`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, '', true, false);
};

/**
 * @description Gets the current organization information and installation status.
 * @return {Object} 
 */
export const getOrgConfig = () => {
    // pull destination org information from secure storage.
    const url = `${baseUriAgency()}/api/orgConfig`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, '', true, false);
};
export default {
    getOrgMe,
    getDestinationOrgMe,
    postOrganization,
    getOrgConfig
}