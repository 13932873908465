import config from '../../config';

// This file supports basic fetch wrapping utilities, additionally with functions that wrap fetch calls for retry functionality.

/**
 * @description Allows the ability to pull a baseUri from configuration. Allows for the ability to support multi-env application base uris.
 * @return {*} string uri 
 */
export const baseUri = () => {
    return config.apiEndpoint;
};

export const baseUriAgency = () => {
    return config.agencyApiEndpoint;
};

/**
 * @description async wrapper for the fetch event api.
 * @param {*} url string | url that's being called
 * @param {*} options object | fetch options
 * @param {*} token string | token for authentication
 * @param {*} auth bool | sets the auth header (default true)
 * @param {*} cookies bool | adds "credentials: 'include'" to the options (default false)
 * @param {*} contentType string | sets content type in the header (default 'application/json')
 * @param {*} noResponseData bool | does not try to get the response json (default false)
 * @return {*} standard response
 */
export const fetchWrap = async (url, options, token, auth = true, cookies = false, contentType = 'application/json', noResponseData = false) => {

    const standardResponse = {
        success: false,
        status: 0,
        data: {},
        headers: {},
        url: ''
    };

    let headers = {
        'Content-Type': contentType,
        'Accept': 'application/json',
        'Origin': `${window.location.protocol}//${window.location.host}`,
        'Session': sessionStorage.getItem('session') == null ? '' : sessionStorage.getItem('session')  
    };

    if (auth) {
        headers = { ...headers, Authorization: `Bearer ${token}` }
    }

    if (cookies) {
        options = {
            ...options,
            credentials: 'include'
        }
    }

    const response = await fetch(url, {
        ...options,
        headers: headers
    });

    // need to no-content returns
    if (response.status === 204 || noResponseData) {
        standardResponse.data = {};
    } else {
        standardResponse.data = await response.json();
    }

    standardResponse.status = response.status;
    standardResponse.url = response.url;

    response.headers.forEach((value, key) => {
        Object.assign(standardResponse.headers, { [key]: value });
    });

    if (!response.ok) { return Promise.reject(standardResponse); };
    standardResponse.success = true;
    return standardResponse;
};

/**
 * @description async wrapper for the fetchWrap method, allows for retries on the current passed options.
 * @param {*} url string | url that's being called
 * @param {*} options object | fetch options
 * @param {*} token string | token for authentication
 * @param {*} [retries=process.env.REACT_APP_FETCH_RETRIES_COUNT | 3] number | number of retries, defaulted to 3, and defaulted to pull from an appveyor application set variable
 * @return {*} response | last failed response
 */
export const fetchWithRetries = async (url, options, token, auth = true, cookie = false, retries = process.env.REACT_APP_FETCH_RETRIES_COUNT | 3) => {
    const retryErrors = [];
    let responseErrors = [];

    while (retries != 0) {
        retries = retries - 1;

        const envCheck = process.env.REACT_APP_CUSTOM_ENV.trim() == 'local';

        if (process.env.REACT_APP_FETCH_CALL_VERBOSE | envCheck) {
            console.log(`Attempting to URL ${options.method} Request: ${url}. Request Options: ${JSON.stringify(options)}  Retries Left: ${retries}`);
        }
        
        try {
            return await fetchWrap(url, options, token, auth, cookie);
        } 
        catch ({ data, success, status, url }) {
            retryErrors.push(`Attempt: ${3 - retries} Success: ${success} | Response Error: ${JSON.stringify(data)} | HTTP Status: ${status}`);
            responseErrors = [...responseErrors, { data, success, status, url}];
        }
    }

    console.log(`Error Retries: ${JSON.stringify(retryErrors)}`);
    throw responseErrors[responseErrors.length - 1];
};

/**
 * @description async wrapper for the fetch all scenario in which you'd want to settle all promises at the same time.
 * @param {*} promises Array<Promises> | promises to be passed and settled at in one call.
 * @return {*} Array<Response<StandardResponse>> | array of responses
 */
export const fetchAllWrap = async (promises) => {
    const response = await Promise.allSettled(promises);
    return response;
};

/**
 * @description async wrapper for the fetchAllWrap scenario in which you'd want to settle all promises at the same time with retries.
 * @param {*} promises Array<Promises> | promises to be passed and settled at in one call.
 * @param {*} [retries=process.env.REACT_APP_FETCH_RETRIES_COUNT | 3] number | number of retries, defaulted to 3, and defaulted to pull from an appveyor application set variable
 * @return {*} Array<Response<StandardResponse>> | array of responses | last failed array of responses.
 */
export const fetchAllWithRetries = async (promises, retries = process.env.REACT_APP_FETCH_RETRIES_COUNT | 3) => {
    const retryErrors = [];
    let responseErrors = [];

    while (retries != 0) {
        retries = retries - 1;

        const envCheck = process.env.REACT_APP_CUSTOM_ENV.trim() == 'local';

        if (process.env.REACT_APP_FETCH_CALL_VERBOSE | envCheck) {
            console.log(`Attempting to Fetch ALL | Promises Length: ${promises.length} Retries Left: ${retries}`);
        }

        try {
            return await fetchAllWrap(promises);
        }
        catch ({ data, success, status, url }) {
            retryErrors.push(`Attempt: ${3 - retries} Success: ${false} | Response Error: ${JSON.stringify(data)}`);
            responseErrors = [...responseErrors, { data, success, status, url }];
        }
    }

    console.log(`Error Retries: ${JSON.stringify(retryErrors)}`);
    throw responseErrors[responseErrors.length - 1];
}

export default {
    fetchWrap,
    fetchWithRetries,
    fetchAllWrap,
    fetchAllWithRetries,
    baseUri,
    baseUriAgency
}