// React
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next'
// Third Party
import { Box, Button, Grid, Typography, LinearProgress, Divider, Stack, 
    Card, CardMedia, CardContent, CardActions, CardHeader, Avatar, 
    TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell 
} from '@mui/material';
import { Business, CloudSync, Quiz, Settings, Widgets } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';
import GlobalAuthenticatedContextModel from 'common/contexts/GlobalAuthenticatedContextModel';

// Services
import { getAuditMe } from 'services/api/auditService';

// CSS
import './Entry.css';

// Utilities
import { getStorageItem } from 'common/utilities/applicationStorage';

// Constants
import { OrganizationConstants } from 'common/utilities/appConstants';

const Entry = ({}) => {
    const navigate = useNavigate();

    const { globalAuthenticated, setGlobalAuthenticated } = React.useContext(GlobalAuthenticatedContext);

    const [userInfo, setUserInfo] = React.useState(GlobalAuthenticatedContextModel.user);
    const [orgInfo, setOrgInfo] = React.useState(GlobalAuthenticatedContextModel.genesys);
    const [orgEnv, setOrgEnv] = React.useState('');

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [forbidden, setForbidden] = React.useState(false);

    const [orgMetadata, setOrgMetadata] = React.useState([]);
    const [orgFeatures, setOrgFeatures] = React.useState([]);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (globalAuthenticated && globalAuthenticated.contextLoaded) {
            setUserInfo(globalAuthenticated.user);
            setOrgInfo(globalAuthenticated.genesys);
            const env = getStorageItem(OrganizationConstants.UrlAuthShort, undefined, undefined, sessionStorage);
            setOrgEnv(env);
        }
    }, [globalAuthenticated]);

    React.useEffect(() => {
        if (globalAuthenticated && globalAuthenticated.contextLoaded && orgInfo) {
            createMetadataRows();
            createFeatureRows();
        }
    }, [globalAuthenticated, orgInfo])

    const createData = (title, value) => {
        return Object.assign({}, { title, value });
    };

    const createMetadataRows = () => {
        if (orgInfo) {
            const meta = new Set([
                createData('Organization ID', orgInfo.id),
                createData('Default Site ID', orgInfo.defaultSiteId),
                createData('Default Country Code', orgInfo.defaultCountryCode),
                createData('Default Language', orgInfo.defaultLanguage),
                createData('Deletable', orgInfo.deletable ? 'Yes' : 'No'),
                createData('Domain', orgInfo.domain),
                createData('Product Platform', orgInfo.productPlatform),
                createData('State', orgInfo.state),
                createData('Version', orgInfo.version)
            ]);

            setOrgMetadata(meta);
        }
    };

    const createFeatureRows = () => {
        if (orgInfo && orgInfo.features) {
            const meta = new Set([
                createData('Chat', orgInfo.features?.chat ? 'Yes' : 'No'),
                createData('Contact Center', orgInfo.features?.contactCenter ? 'Yes' : 'No'),
                createData('Directory', orgInfo.features?.directory ? 'Yes' : 'No'),
                createData('HIPAA', orgInfo.features.hipaa ? 'Yes' : 'No'),
                createData('PCI', orgInfo.features.pci ? 'Yes' : 'No'),
                createData('Informal Photos', orgInfo.features.informalPhotos ? 'Yes' : 'No'),
                createData('PureCloud', orgInfo.features.purecloud ? 'Yes' : 'No'),
                createData('PureCloud Voice', orgInfo.features.purecloudVoice ? 'Yes' : 'No'),
                createData('Realtime CIC', orgInfo.features.realtimeCIC ? 'Yes' : 'No'),
                createData('Unified Communications', orgInfo.features.unifiedCommunications ? 'Yes' : 'No'),
                createData('XMPP Federation', orgInfo.features.xmppFederation ? 'Yes' : 'No')
            ]);

            setOrgFeatures(meta);
        }
    };

   
    const handleNavigateToOrg = () => {
        navigate('organization');
    };

 

    const handleMailTo = (e) => {
        if (window) {
            window.location.href = "mailto:PSAWSRequests@genesys.com?subject=[Ignite] Help Request&body=See E3S user guide for more details regarding what to include in the body of this email.";
            e.preventDefault();
        }
        return;
    };

    if (forbidden) return <Navigate to={'/unauthorized'} />

    if (loading || (globalAuthenticated && !globalAuthenticated.contextLoaded)) {
        return (
            <React.Fragment>
                <Box sx={{ my: 1, mx: 1 }}>
                    <LinearProgress color="secondary" />
                </Box>
            </React.Fragment>
        )
    }

    if (error) return <ErrorFound error={error} />;

    return (
        <React.Fragment>
            <section>
                <Box sx={{ my: 1, mx: 1}}>
                    <Grid container direction='row' justifyContent="space-between" alignItems="center">
                        <Grid item xs>
                            <Typography variant="h4" color="primary" component="div">
                                {t('Welcome')}, {userInfo.name}
                            </Typography>
                        </Grid>
                    </Grid>
                  
                    <Divider />
                        <Card sx={{ maxWidth: 600, minHeight: 400 }} elevation={3}>
                            <CardHeader avatar={
                                <Avatar elevation={1} sx={{ bgcolor: 'white', border: '2px solid grey'}} aria-label="Genesys Cloud  Agency App">
                                    <Business className='brand-color-orange' />
                                </Avatar>
                            } title="E3S" subheader={t(`SubHeader`)} />
                            <CardMedia
                                sx={{ height: 250 }}
                                image="/resources/jpg/genesys-cloud-accelerator.png"
                                title="E3S"
                            />
                   
                            <CardActions>
                                <Button size="small" onClick={handleNavigateToOrg}>{t('Manage')}</Button>
                            </CardActions>
                        </Card>
                </Box>
            </section>
        </React.Fragment>
    )
};

export default Entry;